<template>
  <div class="logo-container">
    <img class="logo" src="../assets/images/logo-without-letter-o.png"/>
    <img class="letter-o" src="../assets/images/letter-o.gif"/>
  </div>
</template>

<script>
export default {
  name: 'tourialLogo',
  props: {
  }
}
</script>

<style scoped>
.logo {
  width: 100%;
  
  margin: 0;
}

.logo-container {
  width: 250px;
  height: 53px;
}

.letter-o {
  position: relative;
  z-index: 2;
  left: 12%;
  top: -100%;
  height: 53px;

}
</style>
