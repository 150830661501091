<template>
<div class="mobilenavbar-container">
    <vs-button transparent @click="$parent.mobileMenuVisible = !$parent.mobileMenuVisible">
        <img src="../assets/icons/menu/more.png" />
    </vs-button>
    <vs-button transparent @click="navigate('Viaggi')" :class="{'active': active == 'Viaggi'}" :disabled="user == null">
        <img class="inactive" src="../assets/icons/menu/trips.png" />
        <img class="active" src="../assets/icons/menu/trips_active.png" />
    </vs-button>

    <vs-button circle style="height: 70px; width: 70px; border-radius: 100px; margin-top: -30px;" @click="navigate('Esplora')" :class="{'active': active == 'Esplora'}" :disabled="user == null">
        <img src="../assets/icons/menu/explore_white.png" />
    </vs-button>
    <vs-button transparent @click="navigate('Preferiti')" :class="{'active': active == 'Preferiti'}" :disabled="user == null">
        <img class="inactive" src="../assets/icons/menu/m_favourites.png" />
        <img class="active" src="../assets/icons/menu/m_favourites_active.png" />
    </vs-button>

    <vs-button transparent @click="navigate('Profilo')" :class="{'active': active == 'Profilo'}" :disabled="user == null">
       
        <vs-avatar badge-color="danger" badge-position="top-right" size="40" square>
            <img class="inactive" src="../assets/icons/menu/profile.png" />
            <img class="active" src="../assets/icons/menu/profile_active.png" />


            <template #badge v-if="$parent.notificationsCount > 0">
            {{ $parent.notificationsCount > 10 ? '10+' : $parent.notificationsCount }}
            </template>
        </vs-avatar>
    </vs-button>
</div>
</template>

<script>
export default {
    name: 'mobileNavbar',
    props: {
        component: Object,
        user: Object
    },
    data(){
        return {
            active: ''
        }
    },
    methods: {
        navigate(d){
            this.$router.push({
                name: d
            })
            this.active = d;
        }
    },
    components: {},
    watch: {
        '$route'(n) {
            this.active = n.meta.menu;
        },
    }
}
</script>

<style scoped>
.mobilenavbar-container {
    background-color: white;
    height: 70px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.10);
    width: 100vw;
}


</style>

<style>
.mobilenavbar-container .vs-button img.active {
    display: none;
}

.mobilenavbar-container .vs-button.active img.active {
    display: block;
}

.mobilenavbar-container .vs-button.active img.inactive {
    display: none !important;
}

.mobilenavbar-container .vs-button img {
    height: 32px;
    width: auto;

}

.mobilenavbar-container .vs-avatar {
    background: transparent!important;
    box-shadow: none!important;
}

.mobilenavbar-container .vs-avatar-content:hover img {
    transform: unset!important;
}
</style>
