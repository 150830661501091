<template>
  <div class="splash-container" :style="{height: innerHeight + 'px'}" v-if="visible">
    <TourialLogo/>
  </div>
</template>

<script>
import TourialLogo from './TourialLogo.vue';

export default {
    components: { TourialLogo },
    props: {
        visible: Boolean,
        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },

    mounted(){
        this.init();
    },

    methods: {
        init(){
            if(this.visible){
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },

    watch: {
        visible(){
            this.init();
        }
    }
}
</script>

<style scoped>
.splash-container {
    z-index: 11000;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    background: white;
}


</style>